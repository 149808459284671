.fullscreen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}

.layout {
  min-width: 100%;
  min-height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.background {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.indicator {
  z-index: 10000;
}
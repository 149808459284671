.container {
  /* position: fixed; */
  padding: 0px 8px;
  text-align: center;
  background-color: rgb(45, 45, 45);
  border-bottom: 1px solid black;
  z-index: 1;
  width: 100vw;
  box-sizing: border-box;
}

div .button {
  color: rgb(188, 188, 188);
  font-weight: bold;
}
div .buttonActive {
  background-color: rgba(20, 20, 20, 0.8) !important; /* Change to your desired active background color */
}

div .popover {
  /* z-index: 10000; */
  margin-top: 1px;
  background-color: rgba(20, 20, 20, 0.8);
  backdrop-filter: saturate(100%) blur(6px);
  border: 1px solid rgb(64, 64, 64);
  /* border-bottom: 1px solid rgb(64, 64, 64);
  border-right: 1px solid rgb(64, 64, 64);
  border-left: 1px solid rgb(64, 64, 64); */
  /* border: 1px solid black; */
  padding-left: 20px;
  padding-right: 20px;
}

div .item {
  color: rgb(200, 200, 200);
}

div .menulist {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}

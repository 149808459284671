.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-height: 100vh; */
  /* padding-top: 56px; */
  /* padding-bottom: 56px; */
  /* z-index: 10; */
  /* height: 400px; */
  overflow-y: scroll;
  /* height: fit-content; */
  height: calc(100vh - 40px);
  margin-top: 2px;
}
.scrollcontainer {
  width: 100%;
  min-height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 56px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
}
.pagecontainer {
  min-height: 800px;
  padding-top: 32px; /*20 + menuheight*/
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 56px;

  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
  max-width: 1200px;
  /* button {
    padding: 2px 8px;
    height: 32px;
  } */
  background-color: rgb(60, 60, 60);
  border-radius: 16px;
  box-sizing: border-box;
}

div .fieldlabel {
  font-size: 20px;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 12px;
}

.input {
  background-color: white;
  border-radius: 4px;
  padding: 4px 16px;
  height: 42px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
}

.textarea {
  background-color: white;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 16px;
  resize: none;
  width: 100%;
  box-sizing: border-box;
  transition: height 0.1s ease;
}

.topgrid {
  /* padding-left: 1%; */
  /* width: 99%; */
  display: grid;
  grid-template-columns: 1fr 120px 26%; /* Three rows: 100px, 200px, and the remaining space divided equally */
  grid-auto-flow: row;
  gap: 8px;
  margin-bottom: 8px;
  position: relative;
  box-sizing: border-box;
}
.topgridcolumn1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: start;
  /* align-items: center; */
  button {
    width: 300px;
  }
}
.topgridcolumn1 > div {
  padding-left: 1vw;
  box-sizing: border-box;
}
.outcomecontainer {
  margin-bottom: 8px;
}

.centercontainer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}
.topgridcolumn3 {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: start;

  margin-bottom: -90px;
  /* align-items: start; */
}
.grid {
  margin-left: 1vw;
  width: 99%;
  display: grid;
  grid-template-columns: 1fr 120px 27%; /* Three rows: 100px, 200px, and the remaining space divided equally */
  grid-auto-flow: row;
  gap: 8px;
  margin-bottom: 8px;
  position: relative;
  /* box-sizing: border-box; */

  div {
    padding: 2px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

div .button {
  background-color: rgb(245, 245, 245); /* Green */
  border: none;
  color: rgb(30, 30, 30);
  padding: 4px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  text-transform: none;
  font-weight: bold;
  /* width: auto; */

  &:hover {
    background-color: rgb(255, 255, 255, 1);
    color: rgb(30, 30, 30);
  }
  &:disabled {
    background-color: rgb(128, 128, 128, 0.5);
    color: rgb(150, 150, 150);
    cursor: not-allowed;
  }
}
div .aibutton {
  padding: 4px 12px;
  font-weight: bold;
  text-transform: none;
  color: rgb(255, 255, 255);
  background-color: rgb(112, 58, 152);
  font-size: 16px;
  &:hover {
    background-color: rgb(186, 97, 255);
    color: rgb(255, 255, 255);
  }
}

div .tooltip {
  padding-top: 8px;
  /* padding: 20px ; */
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 20px;
  background-color: rgb(255, 255, 255) ;
  color: rgb(0, 0, 0) ;
  
  /* backdrop-filter: saturate(100%) blur(30px) !important; */
  filter: drop-shadow(0px 0px 8px rgb(186, 97, 255));
  z-index: 100;
  /* border is set from props */
  border-radius: 16px ;
  /* overflow: visible; */
  /* background-color: transparent; */

}
div .tooltipcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* background-color: red; */
  /* height: 300px; */
  border-radius: 16px ;
  margin: -20px;
  padding: 20px;
}
div .tooltipbutton {
  width: 120px;
  align-self: center;
  /* background-color: ; */
}
.tooltipcontent {
}

/* .tooltiparrow {
  color: green !important;
}
.tooltipbody {
  background-color: green !important;
}
.tooltiptextcontainer {
  padding: 8px;
} */

/* .metanotes {
  grid-column: 3 / -1;
} */

.fullscreen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.layout {
  min-width: 100vw;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.background {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

.container {
  z-index: 1001;
  background-color: rgb(40, 40, 40);
  display: flex;
  flex-direction: column;

  width: 80%;
  max-width: 600px;
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;

  border-radius: 16px;
}

.menuheader {
  margin-bottom: 12px;
  font-weight: bold;
}
.menubody {
  background-color: rgba(0, 0, 0, 1);
  /* border-radius: 0px 0px 8px 8px ; */
  border-radius: 3px;
  min-height: 32px;
  max-height: 600px;
  overflow: auto;
}
.divider {
  border-bottom: 1px solid rgb(50, 50, 50);
}

div .button {
  width: 80px;
  background-color: rgb(245, 245, 245); /* Green */
  border: none;
  color: rgb(30, 30, 30);
  padding: 4px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  text-transform: none;
  font-weight: bold;

  &:hover {
    background-color: rgb(255, 255, 255, 1);
    color: rgb(30, 30, 30);
  }
  &:disabled {
    background-color: rgb(128, 128, 128, 0.5);
    color: rgb(150, 150, 150);
    cursor: not-allowed;
  }
}

div .textarea {
  background-color: white;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 16px;
  resize: none;
  width: 100%;
  box-sizing: border-box;
  transition: height 0.1s ease;
  height: 42px;
}

.inputcontent {
  display: flex;
  gap: 8px;
}
div .discard {
  color: rgb(186, 97, 255);
  border-color: rgb(186, 97, 255);
  &:hover {
    border-color: rgba(186, 97, 255, 0.5);
    color: rgba(186, 97, 255, 0.5);
  }
}
div .submit {
  background-color: rgb(186, 97, 255);
  &:hover {
    background-color: rgba(186, 97, 255, 0.8);
    color: rgb(255, 255, 255);
  }
}
